@import '/src/assets/theme/variable.less';
.base-sale-countdown {
  color: @text3;
  background: black;
  padding: 0.14rem 0.57rem;
  border-radius: 0.86rem;
  height: 1.71rem;
  svg {
    margin-right: 0.43rem;
    path {
      fill: @text3;
    }
  }
  &.sale-count-down {
    color: @success !important;
    svg {
      path {
        fill: @success !important;
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;