@import '/src/assets/theme/variable.less';

.info-card-home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  margin-bottom: 2rem;
  width: 90vw;
  padding: 0.5rem;
  border:1px solid rgb(255 223 156 / 20%);
  border-radius: 22px;
  position: relative;

  .info-card-home-box {
    position: relative;
    width: 100%;
    padding: 1rem 1rem 0.5rem;
    background: rgb(255 255 255 / 10%);
    border: 2px solid transparent;
    border-radius: 16px;
    // background-clip: padding-box, border-box;
    // background-origin: padding-box, border-box;
    // RGBA(52, 50, 46, 1)
    // background-image: linear-gradient(to right bottom, #3F392D, #1D1C1A), linear-gradient(130deg, #DBBC81, #1D1C1A);

    .info-card-home-box-border {
      position: absolute;
      width: 20rem;

      &:nth-child(1) {
        left: -2px;
        top:0
      }

      &:nth-child(2) {
        right: -2px;
        bottom: -2px;
      }
    }

    .part-img {
      display: flex;
      width: 100%;
      position: relative;
  
      .card-img {
        width: 100%;
        border-radius: 10px;
        // aspect-ratio: 1;
  
        img {
          object-fit: cover;
          
          // width: 100%;
        }
      }
  
      .card-img-series {
        aspect-ratio: auto;
      }
      
      .top-left-tip {
        position: absolute;
        top: 1rem;
        left: 0.71rem;
      }
  
      .bottom-left-tip {
        position: absolute;
        bottom: 1rem;
        left: 0.71rem;
        color: @text1;
      }
  
      .top-left-tip,
      .bottom-left-tip {
        font-size: 0.83rem;
        display: flex;
        background: black;
        padding: 0.14rem 0.47rem;
        border-radius: 0.86rem;
  
        svg {
          margin-right: 0.43rem;
        }
      }

      .sold-out-mask {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        background: @bg-mask;
        
        .sold-out-mask-content {
          background-image: linear-gradient(180deg, rgb(52 52 54 / 60%), #3F392D);
          width: 100%;
          text-align: center;
          border-radius: 1rem 1rem 0 0;
          position: absolute;
          bottom: 0;

          p {
            font-size: 1.2rem;
            font-weight: 600;
            height: 3.3rem;
            line-height: 3.3rem;
            color: #AAAAAB;
          }
        }
      }
    }
    
    .part-content { 
      width: 100%;
      padding: 0.86rem 0 0;
      color: #fff;

      .title {
        // font-size: 1.5rem;
        font-size: 1.15rem;
        font-weight: 700;
        margin-bottom: 0.85rem;

        .title-icon {
          img {
            vertical-align: middle;
          }
        }
      }
  
      .series-name {
        color: #FFDF9C;
        font-size: 0.8rem;
        padding: 0.2rem 0.3rem;
        background-color: #494338;
        margin-right: 1rem;
        border-radius: 0.2rem;
      }
      
      .limit {
        color:#1E1E20;
        font-size: 0.8rem;
        padding: 0.2rem 0.3rem;
        background-color: #FFDF9C;
        border-radius: 10% 0 0 10%;
      }
      
      .number {
        color: #FFDF9C;
        font-size: 0.8rem;
        padding: 0.2rem 0.5rem;
        background-color: #494338;
        border-radius: 0 10% 10% 0;
      }

      .author {
        margin: 0.5rem 0;
        color: #AAAAAB;
      }

      .line {
        width: 100%;
        height: 0.1rem;
        // margin: 0.2rem 0 1rem;
        background: url('/assets/images/海河数藏img/line@2x.png');
        background-size: contain;
      }

      .nft-status {
        color:#FFDF9C;
        font-size: 1.3rem;
        margin-top: 0.5rem;

        .sale-time {
          font-size: 0.8rem;
        }

        .price {
          color: #fff;
          font-size: 1.5rem;
          font-weight: 700;

          .dollar {
            font-size: 1.5rem;
            margin-right: -5px;
          }

          .free-buy {
            color:#FFDF9C;
            font-size: 1.3rem;

            .free {
              img {
                vertical-align: middle;
                height: 1.3rem;
              }
            }
          }

        }
      }
    }
  }

 
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;