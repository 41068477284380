@import '/src/assets/theme/variable.less';

.museum-page {
  position: relative;
  padding: 1.14rem 0 3.82rem;
  height: 100vh;
  overflow: scroll;
  // background: @bg;
  background: url('/assets/images/海河数藏img/首页bg@2x.jpg');

  .book {
    margin: 0 1.07rem 0.86rem;
    background: @card;
    border-radius: 0.14rem;
    padding: 0.57rem;
    font-size: 0.86rem;
    color: @theme;
    line-height: 1.21rem;

    svg {
      margin-right: 0.57rem;
    }
  }

  .subpage {
    min-height: 100%;
    padding: 0.99rem 1.07rem 0;
  }

  .adm-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .adm-tabs-header {
      // border-bottom: none !important;
      margin: 0.5rem 0;
    }

    .adm-tabs-tab-line {
      // display: none;
      width: 3rem !important;
      left: 0.8rem;
      background: #FFDF9C;
    }

    .adm-tabs-content {
      flex: 1;
    }

    .adm-tabs-tab {
      color: @text2;
    }

    .adm-tabs-tab-active {
      color: #FFDF9C !important;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;