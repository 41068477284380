@import '../../../../assets/theme/variable.less';

.contact-us-wrapper {
  .contact-us-content {
    text-align: center;
    padding-top: 11.43rem;

    & > h6 {
      font-size: 1rem;
      color: #aaaaab;
      line-height: 1.36rem;
      margin-bottom: 2.86rem;
    }

    .qr-code {
      width: 10.29rem;
      height: 10.29rem;
    }

    .save-btn {
      margin-top: 2.29rem;
      font-size: 0.86rem;
      color: #fff;
      line-height: 1.21rem;
      justify-content: center;

      & > svg {
        color: #fff;
        width: 0.86rem;
        height: 0.86rem;
        margin-right: 0.57rem;
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;