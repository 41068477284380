.login-tip {
    width: 100vw;
    height: 3rem;
    // line-height: 3rem;
    position: fixed;
    bottom: 3.7rem;
    background-color: #4D4D4D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: .9rem;
    padding: 0 1rem;
    z-index: 10;

    .adm-button {
        background-color: #FFDF9C;
        color: #000;
        padding: 0.2rem 1rem;
        margin-right: 1rem;
        font-size: 1rem;
    }

}

.hidden-tip {
    display: none;
}
@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;