@import './variable.less';

:root:root {
  --adm-button-border-radius: 12px;
  --adm-font-family: pingfangsc-regular, pingfang sc;
}

.app-container .adm-button {
  --background-color: #333 !important;

  border: none !important;

  --color: white;
  --text-color: white;
}

.adm-tabs-header {
  border-bottom: 0.07rem solid #2e2e2e !important;
}

.adm-tabs-tab-active {
  color: @text1 !important;
}

.adm-tabs-content {
  padding: 0 !important;
}

.adm-list-body {
  background: none !important;
  border: none !important;

  .adm-list-item {
    background: none !important;

    .adm-list-item-content {
      border: none !important;
    }

    &:active:not(.adm-list-item-disabled)::after {
      border: none;
    }
  }
}

.adm-popup-body {
  color: @white;
  background: @card !important;
  border-radius: 1.71rem 1.71rem 0 0;
}

.adm-mask {
  // z-index: 100000 !important;
}
