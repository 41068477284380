@import './index.css';
@import './assets/theme/common.less';
@import './assets/theme/components.less';
@import './assets/theme/variable.less';

.app {
  text-align: center;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-container {
  background: @bg;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;