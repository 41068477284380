@import '/src/assets/theme/variable.less';

.verified-modal {
  .adm-dialog-content {
    padding: 1.14rem 1.71rem .85rem;
  }

  .adm-input {
    background: @border3;
    border-radius: 0.14rem;
    padding: 0.36rem 0.57rem;
    height: 2.29rem;

    & > input {
      color: @text1;
    }

    &::placeholder {
      font-size: 1rem;
      color: @text2;
      line-height: 1.71rem;
    }
  }

  .adm-form {
    padding-bottom: 0.3571rem;
  }

  .adm-form .adm-form-footer {
    padding: 0;
  }

  .user-modal-action-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 19.93rem;
    display: flex;

    --border-width: 0;

    border-top: 0.07rem solid @border;

    & > button {
      padding: 0.71rem 2rem;
      font-size: 1rem;
      line-height: 1.71rem;

      &:nth-child(2) {
        border-left: 0.07rem solid @border !important;
      }

      &[type='submit'] {
        --text-color: @theme;
        --color: @theme;
      }

      flex: 1;
      border-radius: 0;
      background-color: transparent;
    }
  }
  
  .input-status-error {
    color: red;
    border: 1px solid red !important;
    margin-top: 1px;
  
    &::placeholder {
      color: red;
    }
  }
  
  .input-transform {
    animation: input-transform 200ms;
  }

  .adm-list-item-content {
    padding: 0;
  }

  .adm-list-item-content-main {
    padding: 0;
  }

  .adm-list-item {
    padding: 0;
    margin-bottom: 0.86rem;
  }

  .adm-form-item.adm-form-item-vertical .adm-form-item-label {
    font-size: 0.86rem;
    color: @text3;
    line-height: 1.71rem;
    margin-bottom: 0.29rem;
  }

  .tel-change-error {
    font-size: 1rem;
    color: @text3;
    line-height: 1.71rem;
    position: relative;

    & > span:first-child {
      padding-top: 0.2rem;
      padding-right: 0.57rem;
      display: inline-block;
    }

    svg {
      width: 1.14rem;
      height: 1.14rem;
    }
  }

  .adm-input-element {
    font-size: 1rem;
    color: @text1;
    line-height: 1.71rem;
  }
}
@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;