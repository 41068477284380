.user-asset-wrapper {
  padding: 1.14rem 1.07rem 4rem;
  height: 100vh;
  overflow-y: scroll;
  // .adm-popup-body {
  //   overflow-y: scroll;
  // }
  // TODO
  .adm-pull-to-refresh-content {
    overflow-y: scroll;
    // height: calc(100vh - 3.43rem);
    padding: 0 1.07rem;
  }

  .adm-infinite-scroll {
    padding: 1.2857rem;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;