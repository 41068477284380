@import '/src/assets/theme/variable.less';

.city-cascader-wrapper {
  .adm-cascader-header {
    padding: 0.57rem 0 0;
  }

  .adm-cascader-header-title {
    font-size: 1.14rem;
    font-weight: 500;
    color: @text1;
    line-height: 1.57rem;
    padding: 1rem 0;
  }

  .adm-cascader-header-button {
    font-size: 1rem;
    color: @text3;
    line-height: 1.43rem;
    padding: 1rem 1.71rem;

    &:nth-of-type(2) {
      color: @theme;
    }
  }

  .adm-cascader-body {
    .adm-cascader-view {
      --height: 25.57rem;
    }
  }

  .adm-tabs-tab-list {
    border-bottom: 1px solid @border;
  }

  .adm-tabs-tab-wrapper {
    padding: 0.71rem 0.855rem;

    .adm-tabs-tab {
      padding: 0;
      font-size: 1rem;
      color: @text1;
      line-height: 1.43rem;
    }

    &:nth-of-type(2) {
      padding-left: 1.71rem;
    }

    .adm-tabs-tab-active {
      color: @theme !important;
    }
  }

  .adm-list-item {
    padding-left: 1.71rem;
  }

  .adm-list-item-content {
    padding: 0;
    font-size: 1rem;
    color: @text3;
    line-height: 1.43rem;
    padding-right: 1.71rem;
  }

  .adm-list-item-content-main {
    padding: 0.86rem 0;
  }

  .adm-check-list-item-extra {
    color: @theme;
    font-size: 1.14rem;
  }

  .adm-cascader-view-item-active {
    background-color: @border3 !important;
  }

  .adm-cascader-view-header-title {
    max-width: 5rem;
  }

  
}


@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;