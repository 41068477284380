@import '/src/assets/theme/variable.less';

.share-modal {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  backdrop-filter: blur(24px);
  background: @bg-share;

  #capture,
  img {
    width: 22.21rem;
  }

  .save-btn {
    display: flex !important;
    margin-top: 1.57rem;
    color: @text1 !important;
    width: 22.21rem;

    svg {
      margin-right: 0.57rem;

      path {
        fill: @text1;
      }

      line {
        stroke: @text1;
      }
    }
  }

  .cancel-btn {
    background: @nav !important;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    height: 3.25rem;
    border: 0.07rem solid @border;
    // padding-top: 1.14rem;
    padding: 0.64rem;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;