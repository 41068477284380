@import '/src/assets/theme/variable.less';

.app-footer {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: black;
  color: @text5;
  border-top: 0.07rem solid @border;
  padding: 0.57rem 0;

  &.hidden {
    display: none;
  }

  p {
    display: flex;
    justify-content: center;
    flex: 1;
    font-size: 0.71rem;

    svg {
      margin-bottom: 0.57rem;
    }
  }

  .active {
    color: @text1;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;