@import '/src/assets/theme/variable.less';

.user-top-wrapper {
  & > img {
    width: 3.43rem;
    height: 3.43rem;
    margin-right: 0.86rem;
  }

  .username {
    font-size: 1rem;
    font-weight: 500;
    color: @text1;
    line-height: 1.43rem;
    padding: 0.29rem 0;
    width: 14rem;
  }

  .tel-phone {
    font-size: 0.86rem;
    color: @text1;
    line-height: 1.21rem;
    opacity: 0.8;
    padding-bottom: 0.57rem;
  }

  .block-address {
    font-size: 0.86rem;
    color: @text3;
    line-height: 1.21rem;

    & > span:first-child {
      margin-right: 0.57rem;
    }

    svg {
      width: 1.14rem;
      height: 1.14rem;
    }
  }

}

.avatar {
  .adm-action-sheet-button-item-wrapper {
    background: @bg;
    color: #fff;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;