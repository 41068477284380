@import '/src/assets/theme/variable.less';

.info-card {
  display: flex;
  flex-direction: column;
  // background: @card;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 0.29rem;
  height: fit-content;

  .part-top {
    display: flex;
    width: 100%;
    position: relative;

    .sold-out-mask {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background: @bg-mask;
      // backdrop-filter: blur(1px);
      p {
        font-size: 1rem;
        font-weight: 600;
        color: #b1b1b3;
        line-height: 1.43rem;
      }
    }

    .card-img {
       position: relative;
       width: 100%;
      //  aspect-ratio: 3/2;
      //  border-radius: 10px 10px 0 0;
       padding-bottom: 66.67%;

      img {
        // width: 100%;
        // height: 7.8rem;
        // aspect-ratio: 3/2;
        // -webkit-aspect-ratio: 3/2;
        border-radius: 10px 10px 0 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .card-img-series {
      img {
        aspect-ratio: auto;
      }
    }
    
    .top-left-tip {
      position: absolute;
      top: 1rem;
      left: 0.71rem;
    }

    .bottom-left-tip {
      position: absolute;
      bottom: 1rem;
      left: 0.71rem;
      color: @text1;
    }

    .top-left-tip,
    .bottom-left-tip {
      font-size: 0.83rem;
      display: flex;
      background: black;
      padding: 0.14rem 0.47rem;
      border-radius: 0.86rem;

      svg {
        margin-right: 0.43rem;
      }
    }
  }

  .part-bottom {
    box-sizing: border-box;
    width: 100%;
    padding: 0.86rem;
    color: white;
    background: rgb(255 255 255 / 10%);
    border-radius: 0 0 10px 10px;
    // background: #fff;
    // opacity: 0.1;

    .row-first {
      font-size: 1.14rem;
      font-weight: 500;
      color: @text1;
      position: relative;

      .title {
        font-size: 0.9rem;
        font-weight: 700;
      }

      .title-icon {
        img {
          width: 1rem;
          // vertical-align: middle;
        }
      }

      .dci-icon {
        position: sticky;
        right: 0.07rem;
        min-width: 0.71rem;
        min-height: 0.71rem;
        margin-left: 0.14rem;
      }

      .type {
        margin-top: 0.57rem;
        width: fit-content;
        height: fit-content;
        font-weight: 400;
        color: @theme;
        font-size: 0.71rem;
        padding: 0.07rem 0.29rem;
        display: flex;
        background: @bg-type;
        border-radius: 0.07rem;
      }
    }

    .row-seconde {
      // margin-top: 0.29rem;

      .author {
        font-size: 0.86rem;
        font-weight: 400;
        color: @text3;
        max-width: 15.3571rem;

        .avatar {
          width: 1.14rem;
          height: 1.14rem;
          margin-right: 0.29rem;
        }
      }

      .price {
        font-weight: 600;
        font-size: 0.9rem;
      }

      .quantity {
        color: #FFDF9C;
        font-size: 0.85rem;
      }
    }
  }

  &.small {
    .bottom-left-tip {
      font-size: 0.71rem;
      padding: 0.07rem 0.57rem;

      svg {
        width: 0.71rem;
      }
    }

    .part-bottom {
      padding: 0.57rem;
      
      .row-first {
        font-size: 0.86rem;

        .title {
          font-weight: 700;
          // line-height: 1.71rem;
        }

        svg {
          min-height: 0.5rem;
        }

        .type {
          padding: 0.07rem 0.14rem 0 0.21rem;
          font-size: 0.57rem;
          font-weight: 400;
          line-height: 0.79rem;
        }
      }

      .nft-id {
        // text-align: center;
        position: relative;
        margin-top: 0.8rem;
  
        img {
          position: absolute;
          left: 0;
          width: 1.5rem;
          height: 1.5rem;
        }
  
        span {
          display: inline-block;
          height: 1.5rem;
          line-height: 1.5rem;
          color: #FFDF9C;
          background-color: rgb(255 223 156 / 15%);
          padding: 0 0.5rem 0 1rem;
          border-radius: 5px;
          margin-left: 0.7rem;
          font-size: 0.9rem;
        }
      }

      .row-number {
        justify-content: flex-start;
        // margin-top: 0.71rem;
        margin-bottom: 0.43rem;
        font-size: 0.57rem;
        color: @text3;
        line-height: 0.79rem;

        svg {
          margin-right: 0.14rem;
        }
      }

      .row-seconde {
        margin-top: 0.57rem;
        align-items: center;

        .price {
          font-size: 1.14rem;
          font-weight: 600;
          line-height: 1.57rem;
        }

        .quantity {
          // font-size: 0.67rem;
          // font-weight: 400;
          // color: @text3;
          // line-height: 0.79rem;
          color: #FFDF9C;
          font-size: 1rem;
        }

        .author {
          color: @text1;
          width: 100%;
          justify-content: flex-start;

          img {
            width: 0.78rem;
            height: 0.78rem;
          }

          span {
            font-size: 0.78rem;
            font-weight: 500;
            line-height: 0.79rem;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;