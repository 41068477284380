@import '/src/assets/theme/variable.less';
.un-logged-in {
  font-size: 0.86rem;
  font-weight: 400;
  color: @text2;
  line-height: 1.21rem;
  span {
    color: @theme;
  }
  svg {
    margin-bottom: 1.14rem;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;