@import '../../../../assets/theme/variable.less';

.redemption-code-wrapper {
  .redemption-code-content {
    padding-top: 10.57rem;

    .adm-tabs-header {
      border: none !important;
    }

    .adm-tabs-tab-line {
      background-color: #fff;
    }

    .adm-tabs-tab {
      font-size: 1.14rem;
      line-height: 1.57rem;
      color: @text3;
      padding: 0.86rem 0;
    }

    .adm-tabs-tab-active {
      font-weight: 500;
    }

    .adm-swiper-slide {
      padding: 0 3.43rem;
    }
  }

  .adm-swiper-track-inner {
    padding-top: 2.86rem;
  }

  .verify-wrapper,
  .exchange-wrapper {
    .adm-input {
      margin-bottom: 4.57rem;
      padding: 0.87rem 0;
      border-bottom: 0.07rem solid @gray;

      --font-size: 1.29rem;

      font-weight: 500;

      --color: #fff;

      line-height: 1.79rem;

      --placeholder-color: @text2;

      .adm-input-clear {
        svg {
          width: 1.14rem;
          color: @text2;
          height: 1.14rem;
        }
      }
    }

    .verify-tip {
      margin-top: 3.43rem;
      font-size: 0.71rem;
      font-weight: 400;
      color: @text8;
      line-height: 1rem;
      padding-left: 1.15rem;
      background-position: left top;
      background-size: 0.86rem 0.86rem;
      background-image: url('../../../../assets/images/verifyTip.svg');
      background-repeat: no-repeat;
    }

    .adm-button {
      width: 100%;
      background: linear-gradient(270deg, #ffecb8 0%, #ffda8c 100%);

      --border-radius: 0.29rem;

      height: 3.14rem;

      --color: #4d432f;
      --text-color: #4d432f;

      font-weight: 500;

      --border-width: 0;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;