@import '/src/assets/theme/variable.less';

.nft-share-card {
  background: @bg;
  // border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 22.145rem;

  .nft-share-img {
    // padding: 0.86rem;
    width: 100%;
    position: relative;
    padding-bottom: 66.67%;


    .img {
      // margin: 0.86rem;
      // width: 20.5rem;
      // width: 100%;
      // aspect-ratio: 3/2;
      // border-radius: 9px;
      border-radius: 9px;
      display: block;
      position: absolute;
      width: calc(100% - 0.86 * 2rem);
      height: calc(100% - 0.86 * 2rem);
      top: 0.86rem;
      bottom: 0.86rem;
      left: 0.86rem;
      right: 0.86rem;

    }
  }

  .share-info {
    // background: @text1;
    padding: 12px;
    color: #fff;

    .nft-name {
      color: #fff;
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    .limit {
      color:#1E1E20;
      font-size: 0.8rem;
      padding: 0.2rem 0.5rem;
      background-color: #FFDF9C;
      border-radius: 10% 0 0 10%;
    }
    
    .number {
      color: #FFDF9C;
      font-size: 0.8rem;
      padding: 0.2rem 0.5rem;
      background-color: #494338;
      border-radius: 0 10% 10% 0;
    }

    .line {
      width: 100%;
      height: 0.2rem;
      margin: 0.2rem 0 1rem;
      background: url('/assets/images/海河数藏img/line@2x.png');
      background-size: contain;
    }

    .bottom {
      margin-top: 1rem;

      .desc {
        color: #FFDF9C;
        font-size: 1rem;

        .logo {
          img {
            // width: 3rem;
            // height: 3rem;
            width: 8rem;
            margin-right: 1rem;
            vertical-align: middle;
          }
        }

        .text {
          color: #959596;
          margin-top: 0.5rem;
        }
      }

      // .qr-code {
      //   // margin-top: 1rem;
      //   width: 50%;
      //   text-align: right;

      //   /* stylelint-disable-next-line no-descending-specificity */
      //   img {
      //     width: 6rem;
      //   }

      //   svg {
      //     width: 100%;
      //     height: 5rem;
      //     padding-left: 2rem;
      //   }
      // }
    }

    .nft-id {
      .desc {
        .title {
          color: #959596;
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        .text {
          color: #FFDF9C;
          font-weight: 700;
          margin: 0.5rem 0 1rem !important;
        }

        .nft-id-text {
          // text-align: center;
          position: relative;
          margin-top: 0.8rem;
    
          img {
            position: absolute;
            left: 0;
            width: 2rem;
            height: 2rem;
          }
    
          span {
            display: inline-block;
            height: 2rem;
            line-height: 2rem;
            color: #FFDF9C;
            background-color: rgb(255 223 156 / 15%);
            padding: 0 0.5rem 0 1.2rem;
            border-radius: 5px;
            margin-left: 1.1rem;
          }
        }
      }

      .authentication {
        position: relative;
        width: 5rem;

        .authentication-img {
          position: absolute;
          bottom: 2rem;
          right: 1rem;
          width: 7rem;
        }
      }
    }

    .row-2 {
      margin-top: 0.71rem;

      > p {
        display: flex;
        align-items: flex-end;
        font-size: 0.86rem;
        font-weight: 500;
        color: @bg;
        line-height: 1.21rem;
      }

      .organization {
        p {
          line-height: 1rem;
          font-size: 0.71rem;
          color: @bg;

          &:first-child {
            margin-bottom: 0.29rem;
          }
        }
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;