.about-us-wrapper {
  overflow: scroll;

  .introduce {
    padding-top: 1.71rem;
    font-size: 0.86rem;
    color: #aaaaab;
    line-height: 1.57rem;
  }

  .about-us-content {
    padding: 1.71rem 2.29rem 5rem;
    position: relative;
    min-height: calc(100vh - 5rem);

    & > img {
      width: 22.21rem;
      height: 11.57rem;
    }

    .protocol-link {
      font-size: 0.86rem;
      color: #ffdf9c;
      line-height: 3.21rem;
      position: absolute;
      left: 0;
      text-align: center;
      width: 100%;
      bottom: 3rem;
    }
  }

  .adm-popup-body {
    background: #1e1e20 !important;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;