@import '/src/assets/theme/variable.less';

.not-created {
  font-size: 0.86rem;
  color: #79797e;
  line-height: 1.36rem;
  padding-top: 5.14rem;
  min-height: 100%;

  p {
    width: 12.29rem;
    text-align: center;
    margin-bottom: 3.43rem;
  }

  img {
    width: 10.29rem;
    height: 10.29rem;
    margin-bottom: 1.71rem;
  }

  div {
    display: flex !important;
    padding: 0.57rem;
    font-size: 0.86rem;
    color: @text1 !important;
    line-height: 1.21rem;

    svg {
      margin-right: 0.29rem;

      path {
        fill: @text1 !important;
      }

      line {
        stroke: @text1 !important;
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;