.user-popup-wrapper {
  .adm-popup-body {
    background: #1e1e20 !important;
  }
}

.protocol-wrapper {
  .introduce {
    padding: 1.71rem 2.29rem;
    padding-top: 1.71rem;
    font-size: 0.86rem;
    color: #aaaaab;
    line-height: 1.57rem;
    height: 90vh;
    overflow: auto;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;