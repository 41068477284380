@import '/src/assets/theme/variable.less';

.base-sale-countdown {
  color: @text3;
  background: black;
  padding: 0.14rem 0.57rem;
  border-radius: 0.86rem;
  height: 1.71rem;

  svg {
    margin-right: 0.43rem;

    path {
      fill: @text3;
    }
  }

  &.sale-count-down {
    color: @success !important;

    svg {
      path {
        fill: @success !important;
      }
    }
  }
}

.sale-count-down-home {
  font-size: 0.9rem;

  img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.1rem;
  }
}

.hot-sale {
  font-weight: 700;
  font-size: 1.15rem;
  padding-top: 0.4rem;
}

.hot-sale-series {
  padding-top: 0;
}

.base-sale-countdown-expect {
  font-weight: 700;
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;