.user-watch-list-wrapper {
  // min-height: 100vh;
  height: 100vh;
  overflow: auto;

  .adm-pull-to-refresh-content {
    padding: 1.14rem 1.07rem 0;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;