@import './variable.less';
@keyframes input-transform {
  0% {
    transform: translateY(3px);
  }

  50% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-between-center {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.width-100 {
  width: 100%;
}

.margin-0 {
  margin: 0 !important;
}

.position-relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.input-status-error {
  color: red;
  border: 1px solid red !important;
  margin-top: 1px;

  &::placeholder {
    color: red;
  }
}

.input-transform {
  animation: input-transform 200ms;
}

/* clear margin & padding */
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, div,/* structural elements */
dl, dt, dd, ul, ol, li, /* list elements */
pre, /* text formatting elements */
fieldset, legend, button, input, textarea, /* form elements */
th, td {
  /* table elements */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
} /* remove italic */

/* set list-style */
ul,
ol {
  list-style: none;
}

/* set text-decoration */
a,
a:hover {
  text-decoration: none;
}

q::before,
q::after {
  content: '';
}

/* clear float */
.clearfix::after {
  content: '020';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content-bg-color {
  background-color: @bg;
}

.filter-extra-wrapper {
  color: @text3;
  font-size: 0.86rem;
  line-height: 1.21rem;

  & > svg {
    width: 1.14rem;
    height: 1.14rem;
    margin-right: 0.57rem;
  }
}

.adm-popover-menu.user-record-popover {
  --background: @card;

  font-size: 0.71rem;
  color: @text3;
  line-height: 1rem;
  box-shadow: 0 0.21rem 0.43rem 0 rgb(0 0 0 / 30%);
  border-radius: 0.29rem;

  .adm-popover-inner-content {
    padding: 0.29rem 0;
  }

  .adm-popover-menu-item-text {
    border: 0;
    padding: 0.5rem 1.57rem 0.5rem 0.86rem;
    font-size: 0.71rem;
    color: @text3;
    line-height: 1rem;
  }

  .adm-popover-menu-item {
    padding: 0;
  }

  .adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled) {
    background-color: @bg;
  }

  .adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled)::after {
    border: none;
  }
}

.popup-content-page {
  position: relative;
  height: 100vh;
  background-color: @bg;
}

.absolute-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
