@import '/src/assets/theme/variable.less';

.user-record-wrapper {
  font-size: 0.86rem;
  color: @text3;
  line-height: 1.21rem;
  padding: 1.64rem 0 2.86rem;
  // display: flex;
  // justify-content: left;

  & > li {
    flex: 1;
    // float: left;
    text-align: center;
    position: relative;

    a {
      color: #fff;
    }
    
    svg {
      width: 2rem;
      height: 2.07rem;
    }

    &:not(&:last-child)::after {
      content: '';
      height: 1.79rem;
      width: 0;
      border-width: 0;
      border-left: 0.07rem solid @border;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;