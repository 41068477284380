@import '/src/assets/theme/variable.less';

.ranking-subpage {
  color: @text1;
  padding-top: 1.71rem !important;

  .ranking-header {
    font-size: 0.86rem;
    color: @text3;
    line-height: 1.21rem;
  }

  .ranking-list {
    padding: 1.14rem 0;

    .item {
      img {
        width: 1.14rem;
      }

      margin-bottom: 0.57rem;
      padding: 0.43rem 0;

      p {
        span {
          color: @text3 !important;
          width: 1rem;
          margin-right: 0.57rem;
        }
      }

      &.self {
        p {
          color: @text6;

          span {
            color: @text6 !important;
          }
        }
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;