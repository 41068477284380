@import '/src/assets/theme/variable.less';

.user-info-wrapper {
  height: 100vh;
  padding: 0 1.07rem;

  .user-info-content {
    padding-top: 1.14rem;

    & > li {
      background-color: @card;
      border-radius: 0.29rem;
      padding: 1.14rem 1.43rem;
      margin-bottom: 0.86rem;

      .title {
        font-size: 1rem;
        color: @text1;
        line-height: 1.43rem;
      }

      .value {
        max-width: 70%;
        font-size: 0.86rem;
        color: @text3;
        line-height: 1.21rem;
        padding-right: 1.43rem;
        background-image: url('../../../../assets/images/goBack.svg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 0.57rem 0.57rem;
      }
    }
  }

  .adm-dialog-footer {
    display: none;
  }
}

.certified-img {
  margin-right: 2px;
  vertical-align: middle;
  padding-bottom: 2px;
}

/* 
* modal content
*/
.user-info-setting-modal {
  .adm-dialog-content {
    padding-top: 1.14rem;
  }

  .old-nickname {
    font-size: 0.86rem;
    color: @text1;
    line-height: 1.71rem;
    padding-bottom: 1.14rem;
    text-align: center;
  }

  .adm-input {
    background: @border3;
    border-radius: 0.14rem;
    padding: 0.36rem 0.57rem;
    height: 2.29rem;

    & > input {
      color: @text1;
    }

    &::placeholder {
      font-size: 1rem;
      color: @text2;
      line-height: 1.71rem;
    }
  }

  .adm-form {
    padding-bottom: 0.3571rem;
  }

  .adm-list-item-content {
    padding: 0;
  }

  .adm-list-item-content-main {
    padding: 0;
  }

  .adm-form .adm-form-footer {
    padding: 0;
  }

  .user-modal-action-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 19.93rem;
    display: flex;

    --border-width: 0;

    border-top: 0.07rem solid @border;

    & > button {
      padding: 0.71rem 2rem;
      font-size: 1rem;
      line-height: 1.71rem;

      &:nth-child(2) {
        border-left: 0.07rem solid @border !important;
      }

      &[type='submit'] {
        --text-color: @theme;
        --color: @theme;
      }

      flex: 1;
      border-radius: 0;
      background-color: transparent;
    }
  }

  .adm-list-item {
    padding: 0;
    margin-bottom: 0.86rem;
  }

  .adm-form-item.adm-form-item-vertical .adm-form-item-label {
    font-size: 0.86rem;
    color: @text3;
    line-height: 1.71rem;
    margin-bottom: 0.29rem;
  }

  .verify-code-form-item .adm-list-item-content {
    padding: 0;
    align-items: flex-end;

    .adm-list-item-content-extra {
      padding-left: 0.86rem;
    }

    .adm-list-item-content-extra .adm-button {
      border-radius: 0.14rem;
      border: 0.07rem solid @border;
      font-size: 0.86rem;
      color: @text1;
      line-height: 1.21rem;
      padding: 0.57rem;
    }
  }

  .tel-change-error {
    font-size: 1rem;
    color: @text3;
    line-height: 1.71rem;
    position: relative;

    & > span:first-child {
      padding-top: 0.2rem;
      padding-right: 0.57rem;
      display: inline-block;
    }

    svg {
      width: 1.14rem;
      height: 1.14rem;
    }
  }

  .adm-input-element {
    font-size: 1rem;
    color: @text1;
    line-height: 1.71rem;
  }

  .city-select-wrapper {
    width: 100%;
    min-height: 2.57rem;
    font-size: 1rem;
    color: @text1;
    line-height: 1.71rem;
    border-radius: 0.14rem;
    text-align: left;

    --background-color: @border3 !important;
    
  }
  
  .input-status-error {
    color: red;
    border: 1px solid red !important;
    margin-top: 1px;
  
    &::placeholder {
      color: red;
    }
  }
  
  .input-transform {
    animation: input-transform 200ms;
  }

  .city-select-text {
    position: absolute;
    top: 34px;
    padding-left: 10px;
    font-size: 14px;
    color: #fff;
  }

}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;