.transfer-record-wrapper {
  .adm-pull-to-refresh {
    padding: 1.14rem 0 0;
    height: calc(100vh - 3.43rem);
    
    .adm-pull-to-refresh-content {
      height: 100%;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;