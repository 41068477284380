.user-setting-list-wrapper {
  & > li {
    margin-bottom: 0.86rem;
    padding: 1.14rem 1.43rem;
    background: #2b2b2e;
    font-size: 1rem;
    color: #ffffff;
    line-height: 1.43rem;
    position: relative;
    border-radius: 0.29rem;
    &::after {
      content: '';
      display: inline-block;
      width: 1.14rem;
      height: 1.14rem;
      background-image: url('../../../../assets/images/goBack.svg');
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 1.43rem;
    }

    & > svg {
      width: 1.43rem;
      height: 1.43rem;
      margin-right: 0.86rem;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;