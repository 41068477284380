@import '/src/assets/theme/variable.less';

.common-modal {
  background: @card;
  padding-top: 1.14rem !important;
  width: 19.93rem;

  .adm-dialog-title {
    color: white;
    font-weight: 400;
    font-size: 1.14rem !important;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.71rem;

    svg {
      margin-right: 0.57rem;
    }
  }

  .adm-dialog-content {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.71rem !important;
    padding: 0.86rem 1.71rem 2.93rem;
    color: @text3 !important;

    svg {
      margin-right: 0.57rem;
      margin-top: 0.29rem;
    }
  }

  .adm-dialog-footer {
    height: 3.14rem !important;

    .adm-dialog-action-row {
      border-top: 0.07rem solid @border !important;

      .cancel {
        font-size: 1rem !important;
        color: @text3 !important;
        line-height: 1.71rem !important;
      }

      .confirm {
        font-size: 1rem !important;
        color: @theme !important;
        line-height: 1.71rem !important;
      }

      .logout {
        font-size: 1rem;
        line-height: 1.71rem;
        color: @text8;
      }

      button {
        background: @card !important;
        border-right: 0.07rem solid @border !important;

        &:last-child {
          border: 0 !important;
        }
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;