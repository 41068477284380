@import '/src/assets/theme/variable.less';

#capture {
  background: @bg;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > img {
    margin: 0.86rem;
    width: 20.5rem;
    aspect-ratio: 1;
  }

  .share-info {
    background: @text1;
    padding: 12px;

    * {
      color: @bg;
    }

    .title-wrap {
      margin-top: 0.21rem;

      img {
        width: 2.71rem;
        height: 2.71rem;
        margin-right: 0.57rem;
      }

      .title {
        font-size: 1.14rem;
        font-weight: 500;
        color: @bg;
        line-height: 1.57rem;
      }

      .desc {
        position: relative;
        margin-top: 0.14rem;
        font-size: 0.71rem;
        font-weight: 400;
        color: @text3;
        line-height: 1rem;

        svg {
          position: absolute;
        }
      }
    }

    .row-2 {
      margin-top: 0.71rem;

      > p {
        display: flex;
        align-items: flex-end;
        font-size: 0.86rem;
        font-weight: 500;
        color: @bg;
        line-height: 1.21rem;
      }

      .organization {
        p {
          line-height: 1rem;
          font-size: 0.71rem;
          color: @bg;

          &:first-child {
            margin-bottom: 0.29rem;
          }
        }
      }
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;