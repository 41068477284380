@import '/src/assets/theme/variable.less';

.order-record-wrapper {
  .adm-pull-to-refresh {
    // padding: 1.14rem 0 0;
  }
}

.order-record-content {
  height: calc(100vh - 3.43rem);
  overflow: auto;

  // & ::-webkit-scrollbar{
  //   display: none;
  // }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;