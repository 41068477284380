@import '../../../../assets/theme/variable.less';
.no-repeat-center-bg(@url) {
  background-position: center center;
  background-size: cover;
  background-image: @url;
  background-repeat: no-repeat;
}

.watch-list-item {
  background: @card;
  border-radius: 0.29rem;
  padding: 0.86rem;
  margin-bottom: 0.86rem;

  .watch-item-cover {
    width: 4rem;
    height: 4rem;
    margin-right: 1.14rem;
  }

  .watch-list-detail {
    .info {
      max-width: 13rem;
      margin-right: 2.5rem;
    }

    h4 .title,
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    h4 {
      font-size: 0.86rem;
      color: #fff;
      line-height: 1.71rem;
      margin-bottom: 0.57rem;
      position: relative;

      .verify {
        position: absolute;
        top: 0;
        right: -0.6rem;
        font-size: 0.43rem;
        width: 0.43rem;
        height: 0.43rem;
        line-height: 1;
      }
    }

    .introduce {
      font-size: 0.71rem;
      color: @text3;
      line-height: 1rem;
    }
  }

  .fav-wrapper {
    width: 2.29rem;
    height: 2.29rem;
    .no-repeat-center-bg(url('../../../../assets/images/noFav.svg'));
  }

  .is-fav {
    background-image: url('../../../../assets/images/favIcon.svg');
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;