@import '/src/assets/theme/variable.less';

.app-header {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @bg;
  color: @text1;
  padding: 0.57rem 1.07rem;
  position: sticky;
  left: 0;
  right: 0;
  min-height: 3.43rem;
  font-size: 1rem;
  line-height: 1.43rem;

  &.transparent {
    position: fixed;
    background-color: transparent;
  }

  &.hide-header {
    display: none;
  }

  .part-left {
    display: flex;
  }

  .logo {
    width: 9rem;
  }

  .title {
    font-size: 1.29rem;
    font-weight: 500;
    margin-left: 0.57rem;
    line-height: 1.71rem;
  }

  .btn-icon {
    width: 2.29rem;
    height: 2.29rem;
    margin-right: 0.86rem;

    img {
      width: 100%;
      height: 100%;
    }

    &:last-child {
      margin: 0;
    }
  }

  .transparent-icon {
    background: @btn-header;
    border-radius: 1.14rem;
    backdrop-filter: blur(10px);

    img {
      width: 1.29rem;
      height: 1.39rem;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;