.download {
    width: 100vw;
    height: 60vw;
    
    img {
        width: 100%;
        aspect-ratio: 1;
    }

    #mse {
        width: 100% !important;
        aspect-ratio: 1;
    }

    #mse1 {
        width: 100% !important;
        aspect-ratio: 1;
    }

    .save-text {
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 1rem;
        margin-top: 20px;
    }
}

.mask-content {
    position: absolute;
    top: 50%;
    left: 20%;
    width: 100vw;
    height: 150px;
    margin-top: -75px;
    margin-left: -75px;
    font-size: 1.2rem;
    color:#fff;
    text-align: center;
    
    .mask-content-button {
        margin-top: 20px;
    }
}
@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;