.auth-modal-content {
  svg {
    margin-top: 0.21rem;
    margin-right: 0.57rem;
  }

  font-size: 1rem;
  line-height: 1.71rem;
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;