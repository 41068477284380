.base-toast-mask {
  .adm-toast-main.adm-toast-main-icon {
    background-color: #1e1e20;
    width: 8.57rem;
    padding: 1.43rem 0 1.14rem;
    .adm-toast-icon {
      font-size: 0;
      margin-bottom: 1.14rem;
      svg {
        width: 3.43rem;
        height: 3.43rem;
      }
    }
    .adm-auto-center-content {
      font-size: 1rem;
      color: #ffffff;
      line-height: 1.43rem;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;