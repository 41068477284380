@import '/src/assets/theme/variable.less';

.personal-card-list {
  position: relative;
  margin-bottom: 0.57rem;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .total-count {
      font-size: 1.1rem;
      font-weight: 700;
      color: #fff;

      span {
        color: #FFDF9C;
      }
    }

  }

  .sort-btn {
    color: #79797E;
    // width: rem;
    border-radius: 3rem;
    background-color: #343436;
    font-size: 0.85rem;
    padding: 0 0.1rem;

    /* stylelint-disable-next-line no-descending-specificity */
    span {
      display: inline-block;
      height: 1.8rem;
      line-height: 1.8rem;
      width: 3.1rem;
      text-align: center;
    }

    .active {
      height: 1.65rem;
      line-height: 1.65rem;
      color: #fff;
      background-color: #1E1E20;
      border-radius: 3rem;
    }

  }

  .list {
    display: grid;
    grid-template-columns: calc(50% - 0.71rem / 2) calc(50% - 0.71rem / 2);
    row-gap: 0.71rem;
    column-gap: 0.71rem;
    grid-auto-flow: row dense;
  }

  .adm-collapse {
    margin-bottom: 1.14rem;
  }

  .adm-infinite-scroll {
    padding: 2.29rem;
  }

  .adm-collapse-panel-header {
    background: #303033 !important;
    border-radius: 0.14rem;
    width: fit-content;
    padding: 0.14rem 0.43rem;
    font-size: 0.86rem;
    color: @text3;
    line-height: 1.21rem;

    p svg {
      width: 0.71rem;
    }
  }

  .adm-list-item-content {
    padding: 0;
  }

  .adm-list-item-content-main {
    padding: 0;
    width: 100%;
  }

  .adm-collapse-panel-content {
    .adm-list-item {
      padding-left: 0 !important;
      padding-top: 0.86rem;
      // padding-bottom: 1.14rem;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;