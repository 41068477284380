@import '../../../../assets/theme/variable.less';

.user-no-login-wrapper {
  flex: 1;
  padding: 1.1rem 1.07rem 0;
  display: flex;
  flex-direction: column;

  .login-btn {
    background: linear-gradient(270deg, #ffecb8 0%, #ffda8c 100%);
    width: 6.86rem;
    height: 2.57rem;

    --text-color: @text4;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.43rem;
    margin-top: -3.86rem;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;