@import '/src/assets/theme/variable.less';

.home-page {
  // padding: 4.6rem 1.07rem 0;
  // padding: 1.14rem 1.07rem 1rem;
  height: 100vh;
  overflow: scroll;
  background: url('/assets/images/海河数藏img/首页bg@2x.jpg');
  background-size: 100% 100%;

  .banner {
    // padding: 0 0.5rem;
    .swiper-container {
      position: relative;
      // height: 100px;
      // overflow: hidden;
      margin: 20px 0;
    }
  
    .swiper-slide {
      // width: 70vw !important;
      text-align: center;
      font-size: 18px;
      // background: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 300ms;
      transform: scale(0.8);
  
      img {
        // height: 130px;
        border-radius: 10px;
        width: 90vw;
        // object-fit: contain;
      }

    }

    .swiper-slide-active,.swiper-slide-duplicate-active{
      transform: scale(1);
      // transform: translateX(10vw);
    }

  }
  
  .main-content {
    .adm-tabs-header {
      border-bottom: none !important;
    }

    .nav-top {
      .adm-tabs-header {
        position: fixed;
        top: 0;
        z-index: 20000;
        padding: 0.5rem 0;
        background-color: #1E1E20;
        width: 100%;
      }
    }

    .adm-tabs-tab-list {
      width: 80vw;
      padding-left: 0.2rem;
    }

    .adm-tabs-tab-line {
      width: 0 !important;
    }

    .adm-tabs-tab-wrapper {
      .adm-tabs-tab {
        padding: 0;
        color: #79797E;
        font-size: 1.2rem;

        .active-img {
          // width: 6rem;
          height: 3rem;
        }

        .active-img-blindbox {
          // width: 4rem;
          margin-left: -1rem;
          height: 3rem;
          // padding-bottom: 0.3rem;
        }
      }
    }

    .adm-tabs-header-mask-right {
      background: none;
    }

    .adm-tabs-header-mask-left {
      background: none;
    }
  }

  .info-card {
    margin-top: 1.14rem;

    &:first-child {
      margin: 0;
    }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;