@import '../../../../assets/theme/variable.less';

.order-card-wrapper {
  // background: #2b2b2e;
  // border-radius: 0.29rem;
  margin-bottom: 0.86rem;
  // padding: 0.86rem;

  

  .nft-cover {
    width: 4rem;
    height: 4rem;
    margin-right: 1.14rem;
  }

  .adm-list-item,
  .adm-list-item-content,
  .adm-list-item-content-main {
    padding: 0;
  }

  .adm-collapse
  .adm-list
  .adm-list-body
  .adm-list-body-inner,
  .adm-collapse-panel-header {
    background: #2b2b2e !important;
    border-radius: 0.29rem;
    padding: 0.86rem;
    // padding-bottom: 0;
  }

  .adm-collapse-panel-content {
    background: #2b2b2e;
    // border-radius: 0.29rem;
    margin-bottom: 0.86rem;
    // padding: 0.86rem;
    // padding-top: 0;
  }

  .card-item-title {
    font-size: 0.96rem;
    font-weight: 500;
    color: #fff;
    line-height: 1.71rem;
    position: relative;
    width: 80%;

    & > span {
      position: absolute;
      top: 0;
      right: -0.43rem;
      font-size: 0.43rem;
      width: 0.43rem;
      height: 0.43rem;
      line-height: 1;
    }
  }

  .title-wrapper {
    margin-bottom: 0.57rem;
  }

  .order-status {
    font-size: 0.71rem;
    line-height: 1rem;
    // color: #fff;
  }

  .order-status.finished {
    color: @other;
  }

  .order-status.error {
    color: @error;
  }

  .order-status.going {
    color: @success;
  }

  .order-status.cancel {
    color: @gray;
  }

  .order-status.transfer-other {
    color: @text6;
  }

  .row-number {
    // color: #fff;
    // font-size: 0.96rem;
    margin-top: -0.4rem;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #aaaaab;
  }

  .price {
    font-size: 0.96rem;
    font-weight: 600;
    color: #fff;
    line-height: 1.21rem;
  }

  .see-details {
    font-size: 0.71rem;
    color: #79797e;
    line-height: 1rem;

    .see-details-arrow {
      width: 0.71rem;
      height: 0.71rem;
      margin-left: 0.29rem;
    }
  }

  .detail-list-wrapper {
    border-top: 0.04rem solid #4d4d4d;
    // margin-top: 0.86rem;
    padding: 0.86rem;
    // padding-top: 0rem;

    & > div {
      margin-bottom: 0.57rem;
      display: flex;
      align-items: center;

      &:last-child {
        margin: 0;
      }

      & > span {
        line-height: 1rem;
        font-size: 0.71rem;

        &:nth-of-type(1) {
          color: #aaaaab;
          text-align: right;
          min-width: 4.5rem;
        }

        &:nth-of-type(2) {
          color: #fff;
        }
      }
    }
  }

  .order-pay {
    display: flex;
    justify-content: space-around;
    margin-top: 15px !important;

    .order-pay-div {
      color: #fff;
      text-align: center;
      width: 40vw;
      padding: 2px 0;
      position: relative;
      font-size: 0.71rem;
    }

    .order-pay-div::after {
      position: absolute;
	    bottom: 0;
	    // z-index: -1;
	    width: 200%;
	    height: 200%;
	    content: "";
	    display: block;
	    border: 1px solid #fff;
	    border-radius: 5px;
	    transform: scale(0.5);
	    transform-origin: left bottom;
	  }
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;