.base-title-wrapper {
  height: 3.43rem;
  // position: relative;
  .base-title {
    background-color: #1e1e20;
    position: relative;
    padding: 1rem 0;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    line-height: 1.43rem;
    text-align: center;

    & > svg {
      position: absolute;
      left: 1.07rem;
      width: 1.29rem;
      height: 1.29rem;
    }
  }

  .base-title.title-fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem 1.07rem;
    z-index: 999;
  }

  .base-title-extra {
    position: absolute;
    right: 1.07rem;
    top: 1rem;
  }
}

@app-prefix: undefined;@ant-prefix: undefined;@primary-color: #5e67ff;